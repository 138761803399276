/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect, Suspense } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHeader } from "../../contexts/HeaderContextProvider";
import {
  InstallationPages,
} from "../../types/types";
import { SpinnyThingy } from "../Misc/SpinnyThingy";
import ScrollToTop from "../../utils/ScrollToTop";
import { ChartDataContextProvider } from "../../contexts/ChartDataContext";

const LazyAdmin = React.lazy(() => import("../Admin/AdminRoutes"));
const DeviceListPageManager = React.lazy(
  () => import("../../components/DeviceList/DeviceListPageManager"),
);
const SensorDataContainer = React.lazy(
  () => import("../../containers/SensorDataContainer"),
);
const UserPageManager = React.lazy(
  () => import("../../containers/UserPageManager"),
);
const AlarmsPage = React.lazy(
  () => import("../Alarms/AlarmsPage"),
);
const AlarmLogsManager = React.lazy(
  () => import("../AlarmsLogs/AlarmLogsManager"),
);

interface MatchParams {  // for List View and Installation page URLs
  mode: InstallationPages;
  id?: string;
}

export function DashboardRoutes(): React.ReactElement {
  const { t } = useTranslation();
  const { updateDocumentTitle } = useHeader();
  const location = useLocation();
  const matchAlarmLvl = useRouteMatch("/dashboard/alarm/level");
  const matchAlarmLog = useRouteMatch("/dashboard/alarm/log");
  const matchRelay = useRouteMatch("/dashboard/relay");
  const matchInstallView = useRouteMatch<MatchParams>(
    "/dashboard/:mode/installation/:id?",
  );
  const matchDashboard = useRouteMatch<MatchParams>(
    "/dashboard/list/:mode",
  );
  const matchAccount = useRouteMatch("/dashboard/account");

  useEffect(() => {
    if (matchAlarmLvl !== null) {
      updateDocumentTitle(t("Dashboard"));
    } else if (matchAlarmLog !== null) {
      updateDocumentTitle(t("Dashboard"));
    } else if (matchRelay !== null) {
      updateDocumentTitle(t("Dashboard"));
    } else if (matchInstallView !== null) {
      updateDocumentTitle(t("Dashboard Installation"));
    } else if (matchDashboard !== null) {
      const { params } = matchDashboard;
      if (params.mode === InstallationPages.Archived) {
        updateDocumentTitle(t("Archive"));
      } else {
        updateDocumentTitle(t("Dashboard"));
      }
    } else if (matchAccount !== null) {
      updateDocumentTitle(t("User account"));
    }
  }, [
    location.pathname,
    matchAccount,
    matchAlarmLog,
    matchAlarmLvl,
    matchDashboard,
    matchInstallView,
    matchRelay,
    t,
    updateDocumentTitle,
  ]);

  return (
    <Suspense fallback={<SpinnyThingy />}>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path="/dashboard/alarm/level"
            component={AlarmsPage}
          />
          <Route path="/dashboard/admin" component={LazyAdmin} />
          <Route
            exact
            path="/dashboard/alarm/log/:id?"
            component={AlarmLogsManager}
          />
          <Route
            exact
            path="/dashboard/:mode/installation/:id?"
            render={() => (
              <ChartDataContextProvider>
                <SensorDataContainer />
              </ChartDataContextProvider>
            )}
          />
          <Route
            exact
            path="/dashboard/list/:mode"
            component={DeviceListPageManager}
          />
          <Route exact path="/dashboard/account" component={UserPageManager} />

          {/* DIFFERENT REDIRECTS */}
          <Route
            exact
            path="/"
            render={() => <Redirect to="/dashboard/list/active" />}
          />
          <Route
            exact
            path="/dashboard"
            render={() => <Redirect to="/dashboard/list/active" />}
          />
          <Route
            exact
            path="/dashboard/list"
            render={() => <Redirect to="/dashboard/list/active" />}
          />
          <Route
            path="/dashboard/default/index"
            // Legacy URL redirect
            render={(props) => {
              let mode = "active";
              if (props.location.search !== null) {
                const getPars = new URLSearchParams(props.location.search);
                if (getPars.get("archive") !== null) {
                  mode = "archive";
                }
              }
              return <Redirect to={`/dashboard/list/${mode}`} />;
            }}
          />
        </Switch>
      </ScrollToTop>
    </Suspense>
  );
}
