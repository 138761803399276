import {
  Device,
  SelectedInstallation,
  STESubSensors,
  EnergyData,
  ChartTypes,
  SensorData,
  IndividualSensorData,
  DeviceNode,
  ReportQueryContainer,
  MaxMinRange,
  Settings,
  User,
  ResponseRelayLabels,
  Materials,
} from "./types";
import {
  AlarmData,
  AlarmsLogsInstalData,
  AlarmsLogsSensorData,
  AlarmTypes,
  ConstraintEnum,
  Email,
  InstalAndAlarmPk,
  InstAddEmailData,
  InstChangeEmailData,
  NodeAlarmEndpointData,
  NodeAndAlarmId,
} from "../components/Alarms/types";
import { AlarmEnum, TSensorTypes } from "./generated_types";

export interface InstallationResponse {
  data: Array<SelectedInstallation>;
}

export interface PwrUsageRequest {
  startDate: Date;
  endDate: Date;
  id: number;
}

export interface PwrUsageResponse {
  data: Array<EnergyData>;
}

export interface ChartDataRequest {
  x1: Date;
  x2: Date;
  chartType: ChartTypes;
  nodes: string;
}

export interface ChartDataResponse {
  data: Array<SensorData>;
}

export interface IndividualSensorRequest {
  id: number;
  count: number;
  page: number | undefined;
  type: string;
  startDate?: Date,
  endDate?: Date,
}

export interface IndividualSensorResponse {
  data: Array<IndividualSensorData>;
  total_pages: number;
  current_page: number;
}

export interface InstallNameIdRequest {
  installationID: number;
}

export interface InstallationInfoPatch {
  sampling_rate: number,
  installationID: number,
}

export interface UpdateNodeNameRequest {
  id: number;
  newName: string;
}

export interface UpdateNodeNameResponse {
  new_name: string;
}

export interface UpdateSensorColorRequest {
  nodeId: number;
  type: TSensorTypes;
  newColor: string;
}

export interface UpdateSensorColorResponse {
  new_color: string;
}

export interface UpdateSensorMaterialRequest {
  instNodeId: number;
  newMaterial: Materials;
}

export interface UpdateSensorMaterialResponse {
  new_material: Materials;
}

export interface GetRelayLabelsRequest {
  id: number;
}

export interface GetRelayLabelsResponse {
  data: Array<ResponseRelayLabels>;
}

export interface UpdateRelayLabelRequest {
  id: number;
  localId: number;
  newLabel: string;
}

export interface UpdateRelayLabelResponse {
  newLabel: string;
}

export interface InstallMaxRangeRequest {
  installationID: number;
}

export interface InstallMaxRangeResponse {
  data: MaxMinRange;
}

export interface InstallNodesRequest {
  installationID: number;
}

export interface InstallNodesResponse {
  data: Array<DeviceNode>;
}

export interface InfoContentRequest {
  type: string;
}

export interface InfoContestResponse {
  body: string;
  title: string;
}

export interface UpdateDeviceLocRequest {
  lat: number;
  lng: number;
  installationID: number;
}

export enum StatusEnum {
  success = "success",
  error = "error",
  authenticationErr = "authenticationErr",
}

export interface MessageResponse {
  status: StatusEnum;
  message: string;
  transCode?: string;
  duplicateName?: string;
}

export interface MessageResponseData extends MessageResponse {
  data: number;
}

export interface ChangeNameRequest {
  newName: string;
  installationID: number;
}

export interface GenerateReportRequest {
  data: Array<ReportQueryContainer>;
  timezone: string,
}

export interface LoginUserRequest {
  username: string,
  password: string,
}

export interface UserResponse {
  data: User,
}

export interface UpdateUserNameSurnameRequest {
  name: string,
  surname: string,
}

export interface UpdateUserPasswordRequest {
  oldPassword: string,
  newPassword: string,
}

export interface ToggleNodeSensorPowerStateRequest {
  id: number,
  state: boolean,
  sensor_type: STESubSensors,
}

export interface ActivityStage2DataResponse {
  device: Device,
  installs: Array<{
    pk: number,
    name: string,
    archived_time: string | null,
  }>,
}

export interface ActivityStage2DataRequest {
  users: Array<number>,
}

export interface ActivityStage3DataRequest {
  selectedDevices: Array<number>,
  selectedUsers: Array<number>,
  startDate: Date,
  endDate: Date,
}

export interface ActivityStage3DataResponse {
  device: string;
  dates: Array<Date>;
}

export interface SetUserAsSupervised {
  currentlyUnsupervised: number,
  newSupervisor: number,
}

export interface SetUserAsUnsupervised {
  currentlySupervised: number,
}

export interface DashboardSettingsResponse {
  data: Settings;
}

export interface AlarmTypesResponse {
  data: AlarmTypes;
}

export interface InstalAndNodeAlarmsRequest {
  // Installation db pk
  pk: number;
}

export interface InstalAndNodeAlarmsResponse {
  node_data: Array<NodeAlarmEndpointData>;
  inst_alarms: Array<AlarmData>;
}

export interface CreateInstAlarmRequest {
  alarm_type: AlarmEnum;
  installation_fk: Array<number>;
}

export interface CreateInstAlarmResponse {
  data: Array<InstalAndAlarmPk>;
}

export interface CreatePasSensAlarmRequest {
  installation_node_fk: Array<number>;
}

export interface CreatePasSensAlarmResponse {
  data: Array<NodeAndAlarmId>;
}

export interface CreateDataSensAlarmRequest {
  threshold_value: number | undefined;
  constraints: ConstraintEnum | undefined;
  alarm_type: AlarmEnum;
  delay_value: number;
  installation_node_fk: Array<number>;
}

export interface CreateDataSensAlarmResponse {
  data: Array<NodeAndAlarmId>;
}

export interface EmailListResponse {
  data: Array<Email>;
}

export interface PostEmailRequest {
  data: InstAddEmailData;
}

export interface ChangeEmailRequest {
  data: InstChangeEmailData;
}

export interface GetSensorLogsRequest {
  installation_id: number;
}

export interface GetSensorLogsResponse {
  data: Array<AlarmsLogsSensorData>;
}

export interface GetInstalLogsRequest {
  installation_id: number;
}

export interface GetInstalLogsResponse {
  data: Array<AlarmsLogsInstalData>;
}
